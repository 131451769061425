<template>
  <!--对账信息模块-->
  <div>
    <el-card shadow="never">
      <!-- 应收结算 -->
      <div class="ageing">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              应收结算
            </div>
            <div class="custom" style="margin-left: 20px">
              <span style="color: #5976E1; font-size: 20px;font-weight: 700;">（未对账）</span>
            </div>
          </div>
        </div>
        <div class="ageing_b" style="padding: 20px;">
          <el-row :gutter="15">
            <el-col :span="19">
              <div style="display: flex;align-items: center;">
                <span style="font-size: 16px;font-weight: 400;color: #ff9b05;">应收款：</span>
                <span style="font-size: 24px;font-weight: 700;color: #ff9b05; margin-left: 10px;">540</span>
                <span style="font-size: 16px;font-weight: 400;color: #4e4e4e; margin-left: 30px;">合同金额：</span>
                <span style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 10px;">540</span>
              </div>
              <div style="background: #f7f8fa;border-radius: 6px; padding: 20px;">
                <el-row :gutter="15">
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">起步价(15公里内)：</span>
                      <span class="xiang_r">120</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">超出里程费(超出10公里)：</span>
                      <span class="xiang_r">20</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">车在地库：</span>
                      <span class="xiang_r">100</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="15">
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">2个辅轮：</span>
                      <span class="xiang_r">100</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">增补困境救援：</span>
                      <span class="xiang_r">100</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">增补搭电救援：</span>
                      <span class="xiang_r">100</span>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="5">
              <div>
                <div style="display: flex;align-items: center;">
                  <span style="width: 5px;height: 16px;background: #a3a4b2; display: inline-block;"></span>
                  <span style="font-size: 18px;font-weight: 500;color: #3f4155;margin-left: 15px;">应收结算</span>
                </div>
                <div style="margin-top: 15px;font-size: 14px;font-weight: 400;color: #3f4155;">
                  合同金额明细：
                </div>
                <div style="font-size: 14px;font-weight: 400;text-align: left;color: #868792;margin-top: 5px;">
                  1. 一口价的显示：一口价            议价的订单显示：议价    超出里程费用、地库、辅轮有就显示，没有不显示
                </div>
                <div style="font-size: 14px;font-weight: 400;text-align: left;color: #868792;">
                  2. 有增补服务项的显示增补项目
                </div>
                <div style="font-size: 14px;font-weight: 400;text-align: left;color: #868792;">
                  3. 客户金额修改过显示议价
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- 应收结算 -->
      <div class="ageing" style="margin-top: 30px;">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              应收结算
            </div>
            <div class="custom" style="margin-left: 20px">
              <span style="color: #FF9B05; font-size: 20px;font-weight: 700;">（待确认）</span>
            </div>
          </div>
        </div>
        <div class="ageing_b" style="padding: 20px;">
          <el-row :gutter="15">
            <el-col :span="19">
              <div style="display: flex;align-items: center;">
                <span style="font-size: 16px;font-weight: 400;color: #ff9b05;">应收款：</span>
                <span style="font-size: 24px;font-weight: 700;color: #ff9b05; margin-left: 10px;">540</span>
                <span style="font-size: 16px;font-weight: 400;color: #4e4e4e; margin-left: 30px;">合同金额：</span>
                <span style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 10px;">540</span>
              </div>
              <div style="background: #f7f8fa;border-radius: 6px; padding: 20px;">
                <el-row :gutter="15">
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">起步价(15公里内)：</span>
                      <span class="xiang_r">120</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">超出里程费(超出10公里)：</span>
                      <span class="xiang_r">20</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">车在地库：</span>
                      <span class="xiang_r">100</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="15">
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">2个辅轮：</span>
                      <span class="xiang_r">100</span>
                    </div>
                  </el-col>
                </el-row>
              </div>

              <div style="display: flex;align-items: center; margin-top: 20px;">
                <span style="font-size: 16px;font-weight: 400;color: #4e4e4e;">订单补扣款：</span>
                <span style="font-size: 16px;font-weight: 400;color: #050505; margin-left: 10px;">-20</span>
              </div>
              <div style="background: #f7f8fa;border-radius: 6px; padding: 20px;">
                <el-row :gutter="15">
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">照片不合格：</span>
                      <span class="xiang_r">120</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="xiang">
                      <span class="xiang_l">照片无水印 ：</span>
                      <span class="xiang_r">20</span>
                    </div>
                  </el-col>
                </el-row>
              </div>

            </el-col>
            <el-col :span="5">
              <div>
                <div style="display: flex;align-items: center;">
                  <span style="width: 5px;height: 16px;background: #a3a4b2; display: inline-block;"></span>
                  <span style="font-size: 18px;font-weight: 500;color: #3f4155;margin-left: 15px;">应收结算</span>
                </div>
                <div style="margin-top: 15px;font-size: 14px;font-weight: 400;color: #3f4155;">
                  合同金额明细：
                </div>
                <div style="font-size: 14px;font-weight: 400;text-align: left;color: #868792;margin-top: 5px;">
                  1. 一口价的显示：一口价            议价的订单显示：议价    超出里程费用、地库、辅轮有就显示，没有不显示
                </div>
                <div style="font-size: 14px;font-weight: 400;text-align: left;color: #868792;">
                  2. 有增补服务项的显示增补项目
                </div>
                <div style="font-size: 14px;font-weight: 400;text-align: left;color: #868792;">
                  3. 客户金额修改过显示议价
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

    </el-card>
  </div>

</template>

<script>
  export default {
    data(){
      return{

      }
    }
  }
</script>

<style lang="scss" scoped>
  .xiang{
    margin-top: 10px;
    margin-bottom: 10px;
    .xiang_l{
      width: 180px;
      display: inline-block;
      text-align: right;
    }
    .xiang_r{
      display: inline-block;
    }
  }
</style>
